import { PdpCssVariables } from 'config/branding/interfaces/pdp';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const pdpCssVariables: PdpCssVariables = {
    '--color-bg-gallery-button': 'var(--color-base-black)',
    '--color-bg-gallery-placeholder': 'var(--color-base-brand-grey-light)',
    '--color-bg-model-size': 'var(--color-base-white)',
    '--color-bg-pdp-important-content': 'var(--color-base-white)',
    '--color-bg-pdp-lenses-bar-active': 'var(--color-base-black)',
    '--color-bg-pdp-lenses-bar': 'var(--color-base-brand-grey-light)',
    '--color-bg-pdp-lenses-icon-active': 'var(--color-base-black)',
    '--color-bg-pdp-lenses-icon': 'var(--color-base-brand-grey-neutral)',
    '--color-bg-pdp-properties': 'var(--color-base-brand-grey-light)',
    '--color-bg-variant-sale-badge': 'var(--color-base-error)',
    '--color-border-gallery-thumbnail-active': 'var(--color-base-black)',
    '--color-border-gallery-thumbnail': 'var(--color-base-brand-grey-light)',
    '--color-border-pdp-accordion-content': 'var(--color-base-brand-grey-light)',
    '--color-border-pdp-accordions': 'var(--color-base-brand-grey-light)',
    '--color-border-pdp-important-content': 'var(--color-base-brand-grey-light)',
    '--color-border-pdp-upsell': 'var(--color-base-brand-grey-light)',
    '--color-border-pdp-usp': 'var(--color-base-brand-grey-light)',
    '--color-text-gallery-button': 'var(--color-base-white)',
    '--color-text-pdp-important-content': 'var(--color-base-brand-grey-dark)',
    '--color-text-pdp-info': 'var(--color-base-black)',
    '--color-text-pdp-lenses': 'var(--color-base-black)',
    '--color-text-pdp-properties': 'var(--color-base-black)',
    '--color-text-pdp-usp': 'var(--color-base-brand-grey-dark)',
    '--color-text-variant-sale-badge': 'var(--color-base-white)',

    // Attributes
    '--color-bg-key-features-tabs': 'var(--color-base-white)',
    '--color-border-key-features-tabs': 'var(--color-base-brand-grey-light)',
    '--color-border-key-features-link-tabs-highlight': 'var(--color-base-black)',

    // Thumbnails
    '--color-border-thumbnail': 'var(--color-base-black)',
    '--color-border-thumbnail-active': 'var(--color-base-white-50)',

    // Swipe indicator
    '--color-bg-swipe-indicator': 'var(--color-base-brand-grey-neutral)',
    '--color-bg-swipe-indicator-active': 'var(--color-base-black)',
};
